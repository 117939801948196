import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../../components/layout/defaultlayout'
import { PostCard }from "../../components/common/cards";
import { MetaData } from '../../components/meta'
import { Pagination } from '../../components/common/misc';

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ){
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`

/**
* This is the blog's page index page (/blog)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*/
const Index = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges
  return (
    <>
      <MetaData location={location} />
      <Layout location={location} isHome={false}>
        <>
          <div className="container">
            <h2> Recent Post </h2>
            <section className="pagination">
              {/* TODO: On moblie the pagination disappers */}
              <Pagination pageContext={pageContext}/>
            </section>
            <section className="post-feed">
              {posts.map(({ node }) => (
                // The tag below includes the markup for each post - components/common/PostCard.js
                <PostCard key={node.id} post={node} />
              ))}
            </section>
           
          </div>
        </>
      </Layout>
    </>
  )
}

// Index.propTypes = {
//     data: PropTypes.shape({
//         allGhostPost: PropTypes.object.isRequired,
//     }).isRequired,
//     location: PropTypes.shape({
//         pathname: PropTypes.string.isRequired,
//     }).isRequired,
//     pageContext: PropTypes.object,
// }

export default Index

